$body-font: Georgia,Cambria,"Times New Roman",Times,serif;
$heading-font: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";


body {
    margin: 0;
    padding: 0;
    background-color: white;
    color: black;
    font-family: $body-font;
    font-size: 22px;
    line-height: 1.5;
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: $heading-font;
    line-height: 1.2;
    margin-top: 1rem;
    margin-bottom: 0.2rem;
  }
  .main-cover {
    height: 66vh;
    background-image: url('/assets/daintree.jpg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }
  .main-cover > h1, .main-cover > h2 {
    text-align: center;
    color: white;
    margin: 0;
  }
  .main-cover > h2 {
    font-size: 28px;
    padding-top: 44px;
    font-weight: normal;
  }
  .main-cover > h1 {
    font-size: 60px;
    letter-spacing: 4px;
    font-weight: bold;
  }
  .body-container {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    background-image: url('/assets/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 0%;
  }
  .body-content {
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 4em;
    max-width: 840px;
    background: rgba(255,255,255,0.75);
  }
  .wider-image-container {
    position:relative;
    float: right;
    right: 50%;
  }
  .wider-image-container > img {
    position: relative;
    right: -50%;
  }
  img.responsive {
    max-width: 99vw;
  }
  a {
    color: black;
    text-decoration: underline;
  }
  a:visited {
    color: #555;
  }
  a:hover {
    color: #55754C;
  }
  h1 {
    font-size: 32px;
  }
  .byline {
    font-family: $heading-font;
    font-size: 18px;
    color: #444;
  }
  div.blog-footer-container {
    width: 100%;
    background-color: #555;
    color: white;
    font-family: $heading-font;
    font-size: 15px;
    text-align: center;
  
  }
  div.blog-footer-container a, div.blog-footer-container a:visited {
    color: #ddd;
  }
  div.blog-footer-container a:hover {
    color: #fff;
  }
  div.blog-footer-content {
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 2em;
    padding-bottom: 2em;
    max-width: 640px;
  }
  code {
    font-family: "Consolas","Droid Sans Mono","Monaco",monospace,monospace;
    white-space: pre-wrap;
    background-color: #f9f2f4;
    border-radius: 4px;
    padding: 4px;
  }
  